body, html{
    height: 100vh;
    background-color: #F4F4F4;
    font-family: 'Open Sans', sans-serif;
}

#screensaver{
    background-color: #000;
}

header, footer{
    background-color: #161615;
}

main > div{
    background-color: #FFF;
}

h1, h2, h3, h4, h5, h6, .card-title{
    font-family: 'Roboto', sans-serif;
}

.font-gold{
    color: #bd2430;
}

a:hover{
    text-decoration: none;
}

.card.active{
    background-color: #bd2430;
    color: white;
}

.bg-warning{
    background-color: #bd2430 !important;
    color: white;
}

.btn-warning{
    background-color: #bd2430;
    border-color: #bd2430;
    color: white;
}
.btn-warning:hover,.btn-warning:active,.btn-warning:focus{
    background-color: #9b1c28;
    border-color: #9b1c28;
    color: white;
    box-shadow: 0 0 0 0.2rem rgb(189 36 48 / 50%);
}

.mapContainer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.mapboxgl-ctrl-bottom-right{
    display: none;
}
